import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { forEach, orderBy } from 'lodash'
import ProgressiveImage from 'react-progressive-image'

import { Layout, GridItemThumb, GridItemList } from '../components'
import { container, padding, bgImage, type, bgIcon } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

import filterArrow from '../assets/icons/filter-arrow.svg'
import thumbIcon from '../assets/icons/thumb-icon.svg'
import listIcon from '../assets/icons/list-icon.svg'
import { useMount } from 'react-use'

const Index = (props) => {
    const graphQuery = useStaticQuery(query)
    const data = props?.pageContext?.data || parseACF(graphQuery, 'allWordpressInfopages')
    const [activeFilter, setActiveFilter] = useState({
        type: 'year',
        order: 'desc',
    })
    const [activeView, setActiveView] = useState('thumb')
    const [sortedProjects, setSortedProjects] = useState(false)
    let projectsData = []
    forEach(data.projects, (item, key) => {
        projectsData.push({
            ...item,
            ...item.acf,
        })
    })

    const filterGrid = () => {
        if (!projectsData || !activeFilter) return false
        let projects = projectsData
        let sortedProjects

        sortedProjects = orderBy(
            projects,
            [activeFilter.type == 'title' ? activeFilter.type : 'start_year'],
            [activeFilter.order]
        )
        setSortedProjects(sortedProjects)
    }

    const setFilter = (type) => {
        if (type === 'year') {
            if (activeFilter.type !== 'year') {
                setActiveFilter({
                    type: 'year',
                    order: 'desc',
                })
            } else {
                const currentOrder = activeFilter.order
                setActiveFilter({
                    type: 'year',
                    order: currentOrder === 'desc' ? 'asc' : 'desc',
                })
            }
        }

        if (type === 'title') {
            if (activeFilter.type !== 'title') {
                setActiveFilter({
                    type: 'title',
                    order: 'desc',
                })
            } else {
                const currentOrder = activeFilter.order
                setActiveFilter({
                    type: 'title',
                    order: currentOrder === 'desc' ? 'asc' : 'desc',
                })
            }
        }
    }

    useMount(() => {
        filterGrid()
    })

    useEffect(() => {
        filterGrid()
    }, [activeFilter])

    const renderFilters = () => {
        return (
            <Sort>
                <Dot />
                <Label>Sort:</Label>
                <YearToggle
                    onClick={() => {
                        setFilter('year')
                    }}
                >
                    <span
                        style={{
                            transform:
                                (activeFilter.type === 'year' &&
                                    activeFilter.order === 'asc')
                                    ? 'scale(-1)'
                                    : 'none',
                            fontStyle: activeFilter?.type === 'year' ? 'italic' : 'normal',
                        }}
                    >
                        Year
                    </span>
                    <Icon
                        src={filterArrow}
                        style={{
                            transform:
                                (activeFilter.type === 'year' &&
                                    activeFilter.order === 'asc')
                                    ? 'rotate(-180deg)'
                                    : 'none',
                        }}
                    />
                </YearToggle>
                <NameToggle
                    onClick={() => {
                        setFilter('title')
                    }}
                >
                    <span
                        style={{
                            transform:
                                (activeFilter.type === 'title' &&
                                    activeFilter.order === 'asc')
                                    ? 'scale(-1)'
                                    : 'none',
                            fontStyle: activeFilter?.type === 'title' ? 'italic' : 'normal',
                        }}
                    >
                        Name
                    </span>
                    <Icon
                        src={filterArrow}
                        style={{
                            transform:
                                (activeFilter.type === 'title' &&
                                    activeFilter.order === 'asc')
                                    ? 'rotate(-180deg)'
                                    : 'none',
                        }}
                    />
                </NameToggle>
            </Sort>
        )
    }

    const renderViews = () => {
        return (
            <View>
                <Dot />
                <Label>View:</Label>
                <ThumbToggle
                    onClick={() => {
                        setActiveView('thumb')
                    }}
                    style={{
                        fontStyle: activeView === 'thumb' ? 'italic' : 'normal',
                    }}
                >
                    <Icon src={thumbIcon} />
                    &nbsp;Thumb
                </ThumbToggle>
                <ListToggle
                    onClick={() => {
                        setActiveView('list')
                    }}
                    style={{
                        fontStyle: activeView === 'list' ? 'italic' : 'normal',
                    }}
                >
                    <Icon src={listIcon} />
                    &nbsp;List
                </ListToggle>
            </View>
        )
    }

    const renderTop = () => {
        return (
            <Top>
                <Heading>Works</Heading>
                {renderFilters()}
                {renderViews()}
            </Top>
        )
    }

    const renderProjects = () => {
        if (!projectsData) return
        const projects = sortedProjects || projectsData

        const items = projects.map((item, i) => {
            if (activeView === 'list') {
                return <GridItemList key={i} item={{ ...item, ...item?.acf }} />
            } else {
                return (
                    <GridItemThumb key={i} item={{ ...item, ...item?.acf }} />
                )
            }
        })

        return (
            <>
                {activeView === 'thumb' && <Grid>{items}</Grid>}
                {activeView === 'list' && (
                    <List>
                        <ListHeadings>
                            <Col>Project</Col>
                            <Col>Year</Col>
                            <Col>Location</Col>
                            <Col>Format</Col>
                        </ListHeadings>
                        {items}
                    </List>
                )}
            </>
        )
    }

    return (
        <Layout meta={data && data.seo} isHome={true}>
            <Wrapper>
                <Container>
                    {renderTop()}
                    {renderProjects()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Label = styled.div``
const Description = styled.div``
const Info = styled.div``
const Dot = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: black;

    ${media.phone`
        width: 18px;
        height: 18px;
    `}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
    ${props => {
        if(!props.landscape) return css`
            ${bgIcon}
        `
    }}
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Top

const YearToggle = styled.div``
const NameToggle = styled.div``
const ThumbToggle = styled.div``
const ListToggle = styled.div``
const Sort = styled.div``
const View = styled.div``

const Top = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 33px;
    border-bottom: 5px solid black;

    ${media.tablet`
        flex-wrap: wrap;
    `}

    &,
    * {
        ${type.heading1}
    }

    ${Heading} {
        margin-right: 64px;

        ${media.tablet`
            margin: 0;
            width: 100%;
        `}
    }

    ${Sort}, ${View} {
        display: flex;
        align-items: center;

        ${Dot}, ${Label} {
            margin-right: 16px;

            ${media.phone`
                margin-right: 11px;
            `}
        }
    }

    // filter toggles

    ${YearToggle}, ${NameToggle}, ${ThumbToggle}, ${ListToggle} {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    ${Sort} {
        margin-right: 36px;

        ${media.phone`
            width: 100%;
            margin-right: 0;
            margin-top: 13px;
        `}
        
        ${YearToggle}, ${NameToggle} {
            span {
                display: inline-block;
                margin-right: 15px;

                ${media.phone`
                    margin-right: 11px;
                `}
            }

            ${Icon} {
                ${media.phone`
                    width: auto;
                    height: 18px;
                `}
            }
        }

        ${YearToggle} {
            margin-right: 19px;
        }
    }

    ${View} {
        ${media.phone`
            width: 100%;
            margin-top: 11px;
        `}
        
        ${ThumbToggle} {
            margin-right: 13px;
        }

        ${Icon} {
            ${media.phone`
                width: 30px;
                height: auto;
            `}
        }
    }
`

// Projects Grid

const Grid = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 47px;
    margin-left: -10px;
    margin-right: -10px;
`

// Projects List

const ListHeadings = styled.div``
const Col = styled.div``

const List = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    ${media.phone`
        margin-top: 0;
    `}

    ${ListHeadings} {
        display: flex;
        margin-bottom: 15px;
        padding-bottom: 14px;
        border-bottom: 3px solid black;

        &,
        * {
            ${type.heading2}
        }

        ${media.phone`
            display: none;
        `}
    }

    /* list layout */
    ${Col}, .list-column {
        &:first-child {
            flex: 35% 0 0;
        }
        &:nth-child(2) {
            flex: 10% 0 0;
        }
        &:nth-child(3) {
            flex: 20% 0 0;
        }
        &:last-child {
            flex: 35% 0 0;
            padding-right: 0;
        }

        ${media.phone`
            flex: initial;
            width: 100%;
        `}
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
